import { SnackbarState } from "@/models/utils/snackbar-state.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "snackbar" })
class SnackbarModule extends VuexModule {
  private snackbarState: SnackbarState = null;

  get message(): string {
    return this.snackbarState ? this.snackbarState.message : "";
  }

  get type(): string {
    return this.snackbarState ? this.snackbarState.type : "";
  }

  get status(): boolean {
    return this.snackbarState ? this.snackbarState.snackbarStatus : false;
  }

  @Mutation
  public setSnackbar(state?: { message: string; type: string }): void {
    if (state) {
      this.snackbarState = {
        message: state.message,
        type: state.type,
        snackbarStatus: true,
      };
    } else {
      this.snackbarState = {
        message: "",
        type: "",
        snackbarStatus: false,
      };
    }
  }

  @Action
  public showSnackbar(state: { message: string; type: string }): void {
    this.context.commit("setSnackbar", {
      message: state.message,
      type: state.type,
    });
  }

  @Action
  public hideSnackbar(): void {
    this.context.commit("setSnackbar");
  }
}

export default SnackbarModule;
