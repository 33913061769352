<template>
  <v-sheet>
    <router-view />
    <v-snackbar
      :timeout="-1"
      :value="snackbar"
      bottom
      right
      elevation="24"
      class="message-snackbar"
      :color="'var(--v-' + type + '-base)'"
    >
      <v-icon :color="'var(--v-light)'" v-if="icon" class="snack-icon"
        >mdi-{{ icon }}</v-icon
      >
      <span :class="type + '-message'">{{ message }}</span>
    </v-snackbar>
    <v-overlay :value="overlay" z-index="1000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-sheet>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "./store";
import OverlayModule from "./store/modules/core/overlay.module";
import SnackbarModule from "./store/modules/core/snackbar.module";

const overlayModule = getModule(OverlayModule, store);
const snackBarModule = getModule(SnackbarModule, store);

@Component
export default class App extends Vue {
  get overlay(): boolean {
    return overlayModule.status;
  }

  get snackbar(): boolean {
    return snackBarModule.status;
  }

  get message(): string {
    return snackBarModule.message;
  }

  get type(): string {
    return snackBarModule.type;
  }

  get icon(): string {
    return this.type === "warning"
      ? "information-outlined"
      : this.type === "error"
      ? "alert-outline"
      : this.type === "success"
      ? "check-circle-outlined"
      : "";
  }
}
</script>

<style lang="sass">
@import './sass/style.sass'

.message-snackbar
   z-index: 10000 !important

.snack-icon
   vertical-align: middle !important
   margin-right: 8px !important

.error-message
   color: var(--v-error-darken2) !important
.success-message
   color: var(--v-success-darken2) !important
.warning-message
   color: var(--v-warning-darken2) !important

html
   overflow-y: auto !important

.v-data-table-header th.sortable .v-data-table-header__icon
   display: inline-flex
   margin-left: 10px

.table_header__actions i,
.table_header__actions i::after,
.table_header__actions i::before
   display: none !important

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button
   -webkit-appearance: none
   -moz-appearance: none
   appearance: none
   display: none
   margin: 0

tbody tr:hover
   background-color: transparent !important

@media #{map-get($display-breakpoints, 'sm-and-down')}
   tr.primary td
      background-color: var(--v-primary-lighten5)

.table--uncollapsed .v-text-field input
   max-width: none
   width: auto
   transition: all .25s

.table--uncollapsed th
   white-space: nowrap

.icon--centered
   display: inline-block
   width: 240px
   text-align: left

.v-main
   padding-bottom: 48px !important

@media #{map-get($display-breakpoints, 'sm-and-down')}
   .v-main
      padding-bottom: 100px !important

.v-application--wrap
   min-height: auto !important

@media #{map-get($display-breakpoints, 'sm-and-up')}
   .icon--centered
      width: 100%
      text-align: center

.mod--search_max_width
   max-width: 650px !important

/* Added styles */

.mod--background_transparent
   background: transparent !important

main
   background-image: url("assets/images/subtle-prism.svg")
   background-repeat: repeat
   min-height: 100vh
   background-size: cover

.container.fill-height
   align-items: flex-start !important
</style>
