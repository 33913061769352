import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import it from "vuetify/lib/locale/it";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#333A62",
          darken1: "#04166D",
          lighten1: "#3A58EE",
          lighten5: "#D9E8FF",
        },
        secondary: {
          base: "#F2F2F2",
          lighten1: "#828282",
          lighten2: "#BDBDBD",
          lighten3: "#E0E0E0",
          lighten4: "#F2F2F2",
        },
        accent: {
          base: "#151515",
        },
        error: {
          base: "#D91C02",
        },
        success: {
          base: "#008043",
        },
        background: {
          base: "#ECECEC",
        },
        light: {
          base: "#FFFFFF",
        },
        dark: {
          base: "#000000",
        },
      },
    },
  },
  lang: {
    locales: { it },
    current: "it",
  },
});
