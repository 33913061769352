import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import Cookies from "js-cookie";
import AuthModule from "@/store/modules/core/auth.module";
import OverlayModule from "@/store/modules/core/overlay.module";
import SnackbarModule from "@/store/modules/core/snackbar.module";
import FiltersStorageModule from "@/store/modules/storage/filtersStorage.module";
import StorageCartModule from "@/store/modules/storage/storageCart.module";
import AbortRequestsModule from "./modules/abort-requests.module";

Vue.use(Vuex);

const vuexAuth = new VuexPersistence({
  key: "vuex-auth",
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => {
    Cookies.set(key, state, {
      expires: 30,
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
    } as Cookies.CookieAttributes);
  },
  modules: ["auth"],
});

const vuexSettings = new VuexPersistence({
  key: "vuex-settings",
  restoreState: (key) => Cookies.getJSON(key),
  saveState: (key, state) => {
    Cookies.set(key, state, {
      expires: 30,
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
    } as Cookies.CookieAttributes);
  },
  modules: ["settings"],
});

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    overlay: OverlayModule,
    snackbar: SnackbarModule,
    storageModule: FiltersStorageModule,
    storageCartModule: StorageCartModule,
    abortRequests: AbortRequestsModule,
  },
  plugins: [vuexAuth.plugin, vuexSettings.plugin],
});

export default store;
