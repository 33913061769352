import { AuthState } from "@/models/utils/auth-state.interface";
import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
@Module({ namespaced: true, name: "auth" })
class AuthModule extends VuexModule {
  private authState: AuthState = null;

  get token(): boolean {
    return this.authState ? this.authState.token : null;
  }
  get role(): string {
    return this.authState != null ? this.authState.role : null;
  }
  get userName(): string {
    return this.authState
      ? this.authState.username
        ? this.authState.username
        : ""
      : "";
  }
  get userEmail(): string {
    return this.authState
      ? this.authState.user
        ? this.authState.user.email
        : ""
      : "";
  }

  @Mutation
  public setLogin(state: AuthState): void {
    this.authState = state;
  }
  @Action
  public login(state: AuthState): void {
    this.context.commit("setLogin", state);
  }

  @Mutation
  public setLogout(): void {
    this.authState = null;
  }
  @Action
  public logout(): void {
    this.context.commit("setLogout");
  }
}

export default AuthModule;
