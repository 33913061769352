import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthModule from "@/store/modules/core/auth.module";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import StorageCartModule from "@/store/modules/storage/storageCart.module";
const storageModule = getModule(StorageCartModule, store);
const authModule = getModule(AuthModule, store);

const Layout = () => import("../views/core/Layout/Default.vue");
const NotFound = () => import("../views/core/NotFound/NotFound.vue");
const Login = () => import("../views/core/Login/Login.vue");
const Home = () => import("../views/core/Home/Home.vue");
const Storage = () => import("../views/Storage/Storage.vue");
const Cart = () => import("../views/Storage/Cart.vue");
const CatalogsReload = () =>
  import("../views/CatalogsReload/CatalogsReload.vue");
const CatalogsUpdate = () =>
  import("../views/CatalogsUpdate/CatalogsUpdate.vue");
const Categories = () => import("../views/Categories/Categories.vue");
const Password = () => import("../views/Password/Password.vue");
const Orders = () => import("../views/Orders/Orders.vue");
const OrderDetails = () => import("../views/OrderDetails/OrderDetails.vue");
const Prezzi = () => import("../views/ChangedPrices/ChangedPrices.vue");
const PriceCheck = () => import("../views/PriceCheck/PriceCheck.vue");
// const News = () => import("../views/News/News.vue");
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/catalogsreload",
        name: "CatalogsReload",
        component: CatalogsReload,
      },
      {
        path: "/catalogsUpdate",
        name: "CatalogsUpdate",
        component: CatalogsUpdate,
      },
      {
        path: "/categories",
        name: "Categories",
        component: Categories,
      },
      {
        path: "/password",
        name: "Password",
        component: Password,
      },
      {
        path: "/orders",
        name: "Orders",
        component: Orders,
      },
      // {
      //   path: "/news",
      //   name: "News",
      //   component: News,
      // },
      // {
      //   path: "/prezzi",
      //   name: "Prezzi",
      //   component: Prezzi,
      // },
      {
        path: "/priceCheck",
        name: "PriceCheck",
        component: PriceCheck,
      },
      {
        path: "/orderDetails",
        name: "OrderDetails",
        component: OrderDetails,
      },
    ],
  },
  {
    path: "/storage",
    name: "Storage",
    component: Storage,
  },
  // {
  //   path: "/orders",
  //   name: "Orders",
  //   component: Orders,
  // },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/newshome",
    beforeEnter() {
      location.href = "https://bracchiruote.it/news";
    },
  },
  {
    path: "/productsservices",
    beforeEnter() {
      location.href = "https://bracchiruote.it/prodotti";
    },
  },
  {
    path: "/history",
    beforeEnter() {
      location.href = "https://bracchiruote.it/azienda";
    },
  },
  {
    path: "/contacts",
    beforeEnter() {
      location.href = "https://bracchiruote.it/contatti";
    },
  },
  {
    name: "Logout",
    path: "/logout",
    component: () => {
      authModule.logout();
      storageModule.removeAllFromCart();
      router.push({ name: "Login" });
    },
  },
  {
    path: "*",
    redirect: "/404",
    component: Layout,
    children: [
      {
        path: "/404",
        name: "NotFound",
        component: NotFound,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  if (
    to.path == "/newshome" ||
    to.path == "/" ||
    to.path == "/home" ||
    to.path == "/history" ||
    to.path == "/productsservices" ||
    to.path == "/contacts"
  ) {
    next();
    return;
  }
  const token = authModule.token;
  if (token) {
    if (to.path === "/login") {
      if (to.query.idOrder != null) {
        next({ path: "/orderDetails", query: to.query });
      } else next({ path: "/storage" });
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      if (
        to.path === "/storage" ||
        to.path === "/password" ||
        to.path === "/categories" ||
        to.path === "/catalogsreload" ||
        to.path === "/catalogsupdate" ||
        // to.path === "/orders" || // Different view depending on user
        to.path === "/priceCheck"
      ) {
        next({ path: "/login" });
      } else {
        next({ path: "/home" });
      }
    }
  }
});

export default router;
