import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { StorageFilters } from "@/models/entities/storage/storageFilters.interface";

@Module({ namespaced: true, name: "storageModule" })
class FiltersStorageModule extends VuexModule {
  private filters: StorageFilters = null;

  @Mutation
  public changeFilters(values: StorageFilters): void {
    this.filters = values;
  }

  @Action
  public setFilters(values: StorageFilters): void {
    this.context.commit("changeFilters", values);
  }

  get getFilters(): StorageFilters {
    return this.filters;
  }
}

export default FiltersStorageModule;
