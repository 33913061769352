import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { StorageElements } from "@/models/entities/storage/storageElements.interface";

@Module({ namespaced: true, name: "storageCartModule" })
class StorageCartModule extends VuexModule {
  private Cart: StorageElements[] = [];

  @Mutation
  public add(value: StorageElements): void {
    const element = this.Cart.filter(
      (x) => x.art_Codice == value.art_Codice && x.itemID == value.itemID
    );
    if (element.length > 0) {
      element[0].qtySelected = value.qtySelected;
    } else {
      this.Cart.push(value);
    }
  }

  @Mutation
  public remove(value: StorageElements): void {
    let finalIndex = -1;
    this.Cart.forEach((element, index) => {
      if (
        element.art_Codice == value.art_Codice &&
        element.itemID == value.itemID
      ) {
        finalIndex = index;
      }
    });
    if (finalIndex != -1) {
      this.Cart.splice(finalIndex, 1);
    }
  }

  @Mutation
  public removeAll(): void {
    this.Cart = [];
  }

  @Action
  public addToCart(value: StorageElements): void {
    this.context.commit("add", value);
  }

  @Action
  public removeFromCart(value: StorageElements): void {
    this.context.commit("remove", value);
  }

  @Action
  public removeAllFromCart(): void {
    this.context.commit("removeAll");
  }

  get cart(): StorageElements[] {
    return this.Cart;
  }

  get count(): number {
    return this.Cart.length;
  }
}

export default StorageCartModule;
